<template>
  <div class="success-text">
    <i class="fa fa-check-circle" aria-hidden="true"></i>
    <h3>Payment has successfully done!</h3>
    <b-button
    @click="$router.push({ name: 'Settings/Invoices'})"
    variant="info"
    class="mt-3">
    Back
    </b-button>
  </div>
</template>
<script>
export default {
  name: 'PaymentSuccess',
};
</script>
<style scoped>
.success-text {
  border: 1px solid gray;
  border-radius: 5%;
  margin: auto;
  margin-top: 200px;
  background-color: rgb(246, 246, 252);
  width: 300px;
  padding: 20px;
  text-align: center;
}
h3 {
  color: green;
}
i {
  color: green;
  font-size: 40px;
}
</style>
